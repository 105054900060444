/*
* section
*/

.section {
    border-radius: 16px;
    padding: 53px 0;
}

@media (max-width: 767px) {
    .section {
        border-radius: 0px;
    }
}

.section-wrapper {
    background-color: $white;
    border-radius: 16px;
    overflow: hidden;

    .section {
        border-radius: 0;
    }
}

@media (max-width: 991px) {
    .section-wrapper {
        border-radius: 0px;
    }
}

.section1 {

    padding-top: rem-calc(50);
    padding-bottom: rem-calc(80);

    .heading-wrapper {
        //  background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 9.16%, rgba(255, 255, 255, 0) 58.02%), #FD6222;
        text-align: center;
        margin-bottom: rem-calc(96);
        position: relative;


        &::before,
        &::after {
            content: "";
            background-image: url("../images/bar1.png");
            width: 20px;
            height: 168px;
            background-size: 100% 100%;
            position: absolute;
            bottom: -100%;
            z-index: 1;
            left: 50%;
            transform: translateX(-50%);
        }

        &::before {
            margin-left: -470px;
        }

        &::after {
            margin-left: 470px;
        }

        h2 {
            font-family: "Lobster", sans-serif;
            color: #fff;
            margin: 0;
        }


        .inner-wrapper {
            padding: rem-calc(36) rem-calc(60) rem-calc(20);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.21) 9.16%, rgba(255, 255, 255, 0) 58.02%), #FD6222;
            border-radius: 15px;
        }
    }
}

.section.box {
    >.container {
        max-width: 880px;
    }

    padding-top: rem-calc(63);
    padding-bottom: rem-calc(140);


    .figure1 {
        left: -5px;
    }
}


.section1 {

    &.intro {
        padding-top: rem-calc(20);
        padding-bottom: 0;
        margin-bottom: rem-calc(-30);

        h1,
        h2 {
            margin-bottom: rem-calc(22);
        }

        li {
            line-height: 1.2;
        }

        p,
        li {
            font-family: "League Gothic", sans-serif;
            font-size: rem-calc(35);
            font-weight: 400;
        }

        .figure2 {
            margin-bottom: rem-calc(-150);
            position: relative;
            z-index: -1;
        }

        .inner {
            padding-top: rem-calc(95);
            padding-bottom: rem-calc(100);
        }
    }
}




@include media-breakpoint-up(md) {
    .section.questions {
        background: $white;
    }
}

@include media-breakpoint-down(lg) {
    .section1.intro {
        .inner {
            padding-top: rem-calc(5);
        }
    }
}


@include media-breakpoint-between(lg, xl) {
    .section1 {
        .heading-wrapper {
            &::before {
                margin-left: -418px;
            }

            &::after {
                margin-left: 418px
            }
        }
    }
}

@include media-breakpoint-between(md, lg) {
    .section1 {
        .heading-wrapper {

            &::before,
            &::after {
                margin: 0;
                left: initial;
                transform: initial;
            }

            &::before {
                left: 20px;
            }

            &::after {
                right: 20px;
            }
        }

        &.intro {
            .list2 li {
                &::before {
                    top: 7px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .section1 {
        .heading-wrapper {

            margin-bottom: 0;
            border-radius: 0;
            margin-bottom: -26px;
            z-index: 10;

            &::before,
            &::after {
                content: none;
            }

            &.bind-mobile {

                &::before,
                &::after {
                    content: "";
                    margin: 0;
                    top: 0;
                    transform: translateY(-50%);
                    height: 90px;
                }

                &::before {
                    left: 10px;
                }

                &::after {
                    right: 10px;
                    left: initial;
                }
            }


            .inner-wrapper {
                clip-path: polygon(100% 0, 100% 90%, 50% 100%, 0 90%, 0 0);
                border-radius: 0;
            }
        }

        &.intro {
            li {
                font-size: rem-calc(25);
                font-family: $font-family-base;
            }
        }
    }


}

@include media-breakpoint-down(md) {
    .section.caq {
        background-color: transparent !important;
    }

    .section {
        .tag {
            top: 22px;
        }
    }

    .section1.intro {
        .inner {
            padding-bottom: 30px;
        }

        p {
            font-size: rem-calc(25);
        }
    }
}

@include media-breakpoint-down(sm) {
    .section1.intro {
        text-align: center;

        li,
        p {
            font-size: rem-calc(14);
        }
    }

    .section {
        &.box {
            padding-left: rem-calc(0);
            padding-right: rem-calc(0);

            .title {
                margin-bottom: rem-calc(20);
            }

            .tag {
                position: static;
                display: block;
                width: calc(100% + 15px);
                margin-bottom: rem-calc(18);

            }

            .figure1,
            .figure1.rotate-45 {
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .figure1.rotate-45 {
                transform: translate(-50%, -50%) rotate(22deg);
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    .section1.intro {
        .inner {
            padding-top: rem-calc(10);
        }

        .figure2 {
            margin-bottom: rem-calc(-102);
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px){
    .section1.intro .inner h1 {
        font-size: rem-calc(52);
    }
}