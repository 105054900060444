
*,
*:after,
*:before {
    box-sizing             : border-box;
    -webkit-box-sizing     : border-box;
    -moz-box-sizing        : border-box;
    -webkit-font-smoothing : antialiased;
    -moz-font-smoothing    : antialiased;
    -o-font-smoothing      : antialiased;
    font-smoothing         : antialiased;
    text-rendering         : optimizeLegibility;

}

.mediPlayer .control {
    opacity        : 0; /* transition: opacity .2s linear; */
    pointer-events : none;
    cursor         : pointer;
}

.mediPlayer .not-started .play, .mediPlayer .paused .play {
    opacity : 1;

}

.mediPlayer .playing .pause {
    opacity : 1;

}

.mediPlayer .playing .play {
    opacity : 0;
}

.mediPlayer .ended .stop {
    opacity        : 1;
    pointer-events : none;
}

.mediPlayer .precache-bar .done {
    opacity : 0;
}

.mediPlayer .not-started .progress-bar, .mediPlayer .ended .progress-bar {
    display : none;
}

.mediPlayer .ended .progress-track {
    stroke-opacity : 1;
}

.mediPlayer .progress-bar,
.mediPlayer .precache-bar {
    transition        : stroke-dashoffset 500ms;

    stroke-dasharray  : 298.1371428256714;
    stroke-dashoffset : 298.1371428256714;
}