.btn.btn-primary, .btn.btn-secondary {
    color: $white;
}

.btn {
    border: 0;
    font-family: 'League Gothic', sans-serif;
    padding-left: rem-calc(24);
    padding-right: rem-calc(24);
    border-radius: 55rem;
    line-height: 1;
}

.btn.btn-secondary {
    font-family: $font-family-base;
}

.button-wrapper {
    max-width: 530px;

    .btn {
        outline: 5px solid rgba(0, 0, 0, 0.4);
        margin-bottom: rem-calc(5);
        color: $body-color
    }
    
   > p {
        font-size: rem-calc(18);
        font-style: italic;
        text-align: center;
        font-weight: 400;
        color: $body-color;
        margin-bottom: rem-calc(15);
        font-family: $font-family-base;
    }

    .button {
        width: 100%;
    }

    a {
        color: $body-color;
        &:hover {
            color: $body-color;
        }
    }
}

.button {
    font-size: rem-calc(50);
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.88);
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: rem-calc(5) rem-calc(15);



    &:hover {
        border-color: inherit;
    }

    &:active:focus {
        box-shadow: none;
    }

    .price {
        min-width: 90px;
        font-size: rem-calc(20);
        color: $primary;
        font-family: "Lobster", sans-serif;
        margin-right: rem-calc(8);

        &.slanted {
            padding-left: rem-calc(15);
            text-align: left;

            em {
                position: relative;
                left: -22px;
                transform: rotate(330deg);
                display: inline-block;
                top: 3px;
                font-style: normal;
            }
        }

        span {
            color: $secondary;
            font-size: rem-calc(24);
            font-family: $font-family-base;
            font-weight: 700;
            display: block;

        }
    }

    svg {
        height: 38px;
        margin-left: rem-calc(7);
    }

    .price, .arrow {
        min-width: 90px;
    }

    .arrow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

@include media-breakpoint-down(lg) {
    .button .price.slanted{
     text-align: center;
     padding-left: 0;
        em {
        position: static;
        transform: none;
    }
    }
}

@include media-breakpoint-down(sm) {
    .button {
        max-width: 275px;
        font-size: rem-calc(30);
        padding: rem-calc(6) rem-calc(20);


        .arrow {
            display: none;
        }
    }

    .button-wrapper {
        .btn {
            justify-content: space-around;
        }
    }
}
