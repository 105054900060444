.dashboard {
    padding-top: rem-calc(100);
    padding-bottom: rem-calc(65);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        a {
            font-size: rem-calc(18);
            text-decoration: none;
            font-weight: 600;

            &:hover {
                color: $secondary
            }
        }
    }

    .contact *,
    ul * {
        color: $white;
    }

    .contact {
        h6 {
            font-family: $font-family-base;
            font-weight: 700;
            font-size: rem-calc(24);
        }

        svg {
            max-width: 40px;

            * {
                fill: $white
            }
        }

        li {
            padding-left: rem-calc(50);
            position: relative;

            span {
                font-weight: 500;
                display: block;
            }

            svg {
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }

    a {
        transition: color .2s linear;
    }
}

.foot-note {
    padding: rem-calc(8) 0;
    background-color: #000;

    ul a,
    p {
        color: $white;
        font-size: rem-calc(14);
    }

    li {
        margin-bottom: 0;
        line-height: 1;
    }

    li+li {
        margin-left: rem-calc(20);
    }

    p {
        margin-bottom: 0;
    }

    a:hover {
        text-decoration: none;
    }
}

@include media-breakpoint-down(xxl) {
    .dashboard {
        li a {
            font-size: rem-calc(16);
        }

        .contact {
            h6 {
                font-size: rem-calc(20);
            }

            p {
                font-size: rem-calc(16);
            }

            svg {
                max-width: 30px;
            }

            li {
                padding-left: rem-calc(40);
            }

            a {
                font-size: rem-calc(20);
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .dashboard {

        padding-bottom: rem-calc(45);

        ul,
        .contact {
            text-align: center;
        }

        .contact {

            h6 {
                font-size: rem-calc(18);
            }

            li {
                padding-left: 0;
                padding-top: 30px;

                p {
                    font-size: rem-calc(14);
                    font-weight: 400;
                    margin-bottom: rem-calc(8);
                }

                span {
                    font-weight: 400;
                }

                svg {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 36px;
                }

                &:not(:last-child) {
                    margin-bottom: rem-calc(30);
                }
            }
        }

        .box1 {
            margin-top: rem-calc(-216);
        }
    }
}