#container {
    display: flex;
    min-width: 320px;
    overflow: hidden;
    min-height: 100vh;
    flex-direction: column;
    position: relative;
}

.inner-container {
    max-width: 1350px;
    margin: auto;
    padding: 0 rem-calc(15);
    padding-top: rem-calc(52);
    width: 100%;

    &::before {
        content: "";
        width: 100%;
        background: linear-gradient(180.07deg, rgba(151, 199, 242, 0) 53.21%, #97C7F2 99.94%), url(../images/cloud.png);
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        background-size: contain;
        height: 100%;
        z-index: -1;
    }
}

#header {
    padding: rem-calc(10) rem-calc(35);
    background-color: #fff;
    border-radius: 16px;
    position: relative;
    margin-bottom: 36px;
}

#content {
    margin-bottom: 50px;
}

#footer {
    background-color: $body-color;

    .foot-note {
        ul {
            display: flex;
            list-style-type: none;
            padding: 0;
            margin: 0;
            
        }

        .social {
            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}

@media (min-width: 3000px) {
    .inner-container {
        &::before {
            background-size: cover;
        }
    }
}

@include media-breakpoint-down(lg) {
    .inner-container {
        padding: 0;
        padding-bottom: rem-calc(200);
    }

    #header {
        padding: rem-calc(10) rem-calc(12);
        border-radius: 0;
    }

    #footer {
        .foot-note {
            padding-top: rem-calc(22);

            p {
                margin-bottom: rem-calc(20);
                font-size: rem-calc(18);
            }

            ul {
                margin-bottom: rem-calc(20);
            }

            a {
                font-size: rem-calc(18);
            }
        }
    }
}

@include media-breakpoint-down(md) {

    .inner-container {
        padding-bottom: rem-calc(130);
    }

    #header {
        margin-bottom: 0;
    }
}

cite{
    display: block;
}

