.dropdown-button {
    &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-left: 2px solid #404041;
        border-bottom: 2px solid #404041;
        display: block;
        margin: auto;
    }
}


.pattern-secondary {
    background: linear-gradient(178.04deg, $secondary 51.78%, #FFFFFF 123.22%);
}

.fs-normal {
    font-style: normal !important;
}

@media (max-width: 767px) {
    .-mx-sm-1 {
        margin-left: -35px !important;
        margin-right: -35px !important;
    }
}

.fw-semi-bold {
    font-weight: 600;
}

.br-bottom-none {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.br-top-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

@include media-breakpoint-up(xl) {
    .mw-xl-1 {
        max-width: 1263px;
    }

    .mw-xl-2 {
        max-width: 1140px;
    }
}

@include media-breakpoint-up(md) {

    .br-5-md-start {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }

    .br-5-md-end {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
    }
}

.bg-color1 {
    background-color: #EAF4FC !important;
}

@media (max-width: 991px) {
    .pb-extra {
        padding-bottom: 100px !important;
    }

    .pt-extra {
        padding-top: 100px !important;
    }
}


.option-label,
.option-label-top,
.option-label-bottom {
    &::before {
        content: 'OR';
        font-family: "League Gothic", sans-serif;
        font-size: rem-calc(50);
        width: 1.6em;
        height: 1.6em;
        text-align: center;
        line-height: 1.6em;
        background-color: $body-color;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        z-index: 1;
    }
}

.option-label-top::before {
    inset: initial;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.option-label-bottom::before {
    inset: initial;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}

@include media-breakpoint-down(md) {
    .box {
        .tag {
            top: 50px;
        }

        &.option-label {
            &::before {
                right: 50%;
                bottom: 0;
                left: initial;
                top: initial;
                transform: translate(50%, 50%);
            }
        }
    }
}


@include media-breakpoint-down(lg) {

    .option-label,
    .option-label-top,
    .option-label-bottom {

        &::before {
            font-size: rem-calc(34);
        }
    }
}

.text-shadow-0 {
    text-shadow: none !important;
}