.reviews {
    padding-top: rem-calc(64);
    padding-bottom: rem-calc(26);
    text-align: center;
    position: relative;

    iframe,
    video {
        width: 100%;
    }


    .figure1 img {
        max-width: 358px;
        width: 100%;
    }

    .figure1 {
        margin-top: -204px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 280px;
        margin-bottom: rem-calc(18);
    }


    p {
        margin-bottom: rem-calc(15);
    }

    .btn {
        margin-bottom: rem-calc(15);
        border-radius: 50rem;
        outline: 5px solid rgba(0, 0, 0, 0.4);;

        img, svg {
            height: 25px;
        }
    }

    cite {
        font-weight: 600;
        font-style: normal;
        position: relative;
        padding: 0 rem-calc(15);
        display: inline-block;

        &::before {
            content: "-";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .audio-wrapper {
        audio {
            position: absolute;
            left: 0;
            visibility: hidden;
        }
    }
}

.mediPlayer {
    &.btn {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;

    svg {
        width: initial;
    }

    .icon2 {
        margin-right: rem-calc(8);
      
    }
    }


    .icon2 {
        border-radius: 50%;
        background-color: #fff;
        display: inline-block;
        cursor: pointer;
    }

    svg {
        width: 80px;
        height: 80px;    
    }

}


@include media-breakpoint-down(md) {
    .reviews {

        iframe,
        video {
            margin-left: -15px;
            width: calc(100% + 30px);
        }

        
        .figure1 img {
            max-width: 300px;
        }

        .btn {
            font-size: rem-calc(14);
        }
    }
}

@include media-breakpoint-down(sm) {
    .reviews {
    .figure1 img {
        max-width: 200px;
    }
}
}