.form {
    .wrapper {
        padding: rem-calc(20) rem-calc(60) rem-calc(10);
        background-color: rgba($color: $gray-100, $alpha: .15);
        margin-bottom: rem-calc(15);

        &.active {
            background-color: rgba($color: $blue, $alpha: .35);
        }

        p,
        span {
            font-size: rem-calc(18);
            margin-bottom: 0;
        }

        label {
            font-weight: 600;
            margin-bottom: rem-calc(6);
            border-radius: 5px;
            display: flex;
            align-items: center;

            img {
                margin-right: 8px;
            }

            span, del {
                margin-left: 8px;
            }
        }

        label span,
        label del,
        .price {
            font-size: rem-calc(24);
            font-weight: 700;
        }

        del {
            color: $gray-100;
        }
    }


    .note {
        background-color: $blue-light;
        font-size: rem-calc(16);
        border-radius: 6px;
        padding: rem-calc(12) rem-calc(30);
        margin-bottom: rem-calc(30);

        p {
            margin-bottom: 0;
        }
    }

    .check-wrapper {
        label {
            position: relative;

            &::before {
                content: "";
                width: 30px;
                height: 30px;
                border-radius: 5px;
                box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
                border: 1px solid $gray-100;
                position: absolute;
                left: -45px;
                top: 5px;
            }

            &::after {
                content: url(../images/check3.svg);
                position: absolute;
                left: -40px;
                top: 7px;
                display: none;
            }

        }

        input {
            position: absolute;
            visibility: hidden;
        }

        input:checked+label::after {
            display: block;
        }

    }

    label {
        font-size: rem-calc(20);
    }

    .logo-wrapper {
        img {
            float: left;
            margin-left: rem-calc(8);
        }
    }

    button[type="submit"] {
        font-family: $font-family-base;
        outline: 5px solid rgba(0, 0, 0, 0.4);
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(30);

        svg {
            margin-right: rem-calc(10);
            height: 30px;
        }
    }


    .order-summary {
        margin-bottom: rem-calc(60);

        ul {
            list-style-type: none;
            padding-left: rem-calc(15);
            margin-bottom: rem-calc(30);
        }

        li {
            color: $gray-100;
            display: flex;

            p {
                margin-bottom: 0;
            }
        }

        .price {
            color: $body-color;
            margin-right: rem-calc(10);
            min-width: 75px;
        }

        .total {
            .price {
                font-size: rem-calc(24);
                font-weight: 700;
            }

            font-size: rem-calc(16);
            border-top: 1px solid $gray-100;
            padding-top: rem-calc(3);
        }

    }

    .quantity {

        button,
        input {
            width: 32px;
            height: 32px;
            border-radius: 2px;
            border: 1.5px solid $body-color;
            background-color: $white;
            text-align: center;
        }

        button {
            padding: 0;
        }

        input {
            border-color: $gray-100;

            &[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

    }

    .radio-wrapper {
        position: relative;

        input {
            position: absolute;
            visibility: hidden;
        }

        label {
            padding-left: rem-calc(35);
            margin-bottom: rem-calc(10);



            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 31px;
                height: 31px;
                border-radius: 50%;
                border: 1px solid #7D7D7D;
                box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
            }

            &::after {
                content: "";
                position: absolute;
                left: 5.69px;
                top: 5.78px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.25);
                background-color: $gray-100;
                display: none;
            }
        }

        input:checked+label::after {
            display: block;
        }
    }

    .price-wrapper {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(8);
        span{
            font-size: rem-calc(60);
            font-family: 'League Gothic', sans-serif;
            margin-right: rem-calc(15);
        }
    }
}


@include media-breakpoint-down(md) {
    .form .wrapper {
        padding-left: rem-calc(12);
        padding-right: rem-calc(12);
        border-radius: 8px;

        .check-wrapper {

        label {
            padding-left: rem-calc(38);
            margin-bottom: rem-calc(10);

            img {
                height: 25px;
            }
            
            span, del {
                margin-left: auto;
            }
        
        }
    }

        label, label span, label del, .price, .offer {
            font-size: rem-calc(14);
        }



        .check-wrapper {
            label::before {
                left: 0;
                top: -3px
            }

            label::after {
                left: 5px;
            }
        }


        p {
            font-size: rem-calc(14);
        }

        .offer {
            color: $secondary;
        }
    }

    .form {
        .note {
            font-size: rem-calc(14);
            border-radius: 0;
            text-align: center;
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }


        button[type="submit"] {
            padding: rem-calc(10) rem-calc(25);
        }


        .quantity {
            span {
                display: block;
            }
        }
    }

}