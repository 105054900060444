
@include media-breakpoint-up(lg) {
    .home .intro figure {
        position: relative;
        z-index: -1;

        img {
            max-height: 450px;
        }
    }
}

@include media-breakpoint-up(md) {
    .home .two-column-layout {

        .option-label::before {
            bottom: 50px;
            top: initial;
        }
    }

   
}

@include media-breakpoint-between(lg, xl) {
    .home .intro figure {
        margin-top: rem-calc(181);
    }
}

@include media-breakpoint-down(lg) {
.home .intro + .section {
    padding-top: rem-calc(285)
}

.home .intro {
    margin-bottom: rem-calc(-288);
}

}

@include media-breakpoint-down(sm) {
    .home .intro {
        margin-bottom: rem-calc(-125);
        
        figure {
        img {
            max-height: 216px;
        }
    }

    & + .section {
        padding-top: rem-calc(157);
    }
}
}

@include media-breakpoint-down(md) {
    .home .two-column-layout >  .box {
        padding-bottom: rem-calc(65);
    }
}


@include media-breakpoint-between(md, lg) {
    .home .box.option-label::before {
       font-size: rem-calc(42);
       width: 70px;
       height: 70px;
       line-height: 70px;
       top: 50%;
    }
}

@media (min-width: 1400px) {
    .home .intro figure img {
        max-height: initial;
        margin-top: -46px;
    }
}
