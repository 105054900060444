/*
*list
*/

//list1
.list1 {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    li {
        flex: 0 0 50%;
        font-weight: 600;
        width: 178px;
        margin: 0;
        padding: 0 12px;

        p {
            font-weight: 600;
        }

        i {
            img {
                max-height: 90px;
            }
        }

        h6 {
            font-weight: 700;
            font-family: $font-family-base;
            font-size: rem-calc(24);
        }
    }

    &.alt{
        margin: 0 -33px;

        li {
            padding: 0 33px;

            p {
                font-weight: 500;
            }

            i {
                img {
                    max-width: 100px;
                    max-height: 100px;
                }
            }
        }
    }


    &.three-column {
        margin: 0 -17px;

        li {
            flex: 0 0 33.3%;
            font-weight: 500;
            padding: 0 17px;

            i {
                img {
                    max-height: 100px;
                    max-width: 100px;
                    padding: 10px;
                }
            }

            p {
                font-weight: 500;
            }
        }
    }

    &.arrow {
        li {
            position: relative;
            padding: 0 54px;

            &::after {
                content: " ";
                background: url("../images/right-arrow2.svg");
                position: absolute;
                top: 30px;
                right: 0;
                height: 50px;
                width: 54px;
                transform: translateX(50%);
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &.icon1 {
        counter-reset: icon1;

        li {

            &::before {
                counter-increment: icon1;
                content: counter(icon1);
                display: block;
                width: 62px;
                height: 60px;
                background-image: url(../images/icon26.svg);
                background-size: contain;
                background-repeat: no-repeat;
                text-align: center;
                line-height: 40px;
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 991px) {
    .list1 {
        &.three-column {
            li {
                flex: 0 0 50%;
                i {
                    img {
                        max-height: 80px;
                        max-width: 80px;
                    }
                }
            }
        }

        &.arrow {
            li {
                padding: 0 10px;
                flex: 0 0 33.33%;
            }
        }
    }
}

@media (max-width: 767px) {
    .list1 {
        li {
            h6 {
                font-size: rem-calc(20);
            }

            p {
                font-size: rem-calc(18);
            }
        }

        &.alt{
            li {
                flex: 0 0 100%;
                text-align: center;
            }
        }

        &.three-column {
            li {
                flex: 0 0 100%;
            }
        }

        &.arrow {
            li {
                padding-bottom: 30px;
                padding-top: 30px;
                flex: 0 0 100%;

                &:last-child {
                    padding-bottom: 0;
                }

                &:first-child {
                    padding-top: 0;
                }

                &::after {
                    top: initial;
                    right: initial;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 50%) rotate(90deg);
                }
            }
        }
    }
}



//list2
.list2 {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: rem-calc(30);


    li {
        padding-left: rem-calc(65);
        position: relative;
        font-size: rem-calc(24);
        font-weight: 700;
    }

    li::before {
        content: "";
        background-image: url('../images/icon10.svg');
        background-size: cover;
        width: 46px;
        height: 46px;
        display: inline-block;
        left: 0;
        position: absolute;
    }
}

@include media-breakpoint-down(lg) {
    .list2 {
        li {
            font-size: rem-calc(20);
            padding-left: rem-calc(40);

            &::before {
                height: 30px;
                width: 30px;
            }
        }
    }
}



@include media-breakpoint-down(sm) {
    .list2 {
        text-align: center;

        li {
            padding-top: rem-calc(30);
            padding-left: 0;
            font-size: rem-calc(16);

            &::before {
                transform: translateX(-50%);
                top: 0;
                left: 50%;
            }
        }
    }
}

//list3
.list3{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
        margin: 0;
        padding: 12px 25px;
        border-bottom: 1px solid $black;
        &:last-child{
            border-bottom: none;
        }
        a{
            text-decoration: none;
            &:hover{
                color: $primary;
            }
        }
    }
}