.pagination {
    justify-content: center;
    margin-bottom: rem-calc(50);

    ul {
        display: flex;
        list-style-type: none;
        padding: 0;
    }

    ul, li {
        margin: 0;
    }

    a {
        text-decoration: none;
        width: calc( 1em + 2px);
        height: calc(1em + 2px);
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        line-height: calc(1em + 2px);
        font-size: rem-calc(18);
        color: $body-color;

        &.prev, &.next, &:hover, &.active {
            color: $white;
        }

        &:hover, &.active {
            background-color: $primary;
        }
    }

    li + li {
        margin-left: rem-calc(5);
    }

    .prev, .next {
        background-color: #ccc;
    }
}