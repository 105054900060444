body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}

body {
  background-color: $blue;
}

button {
  background: transparent;
  border: 0;
}

ul {
  li {
    margin-bottom: rem-calc(14);
  }
}

iframe {
  width: 100%;
}

h1, .h1 {
  margin-bottom: rem-calc(30);
}

// a {
//   text-decoration: underline;
// }

@include media-breakpoint-down(xxl) {
  h1 .h1 {
    font-size: rem-calc(40);
  }
}


@include media-breakpoint-down(md) {
  body {
    font-size: rem-calc(14)
  }
  
  h1, .h1, h2, .h2, h3, .h3 {
     font-size: rem-calc(30)
  }

}


