/*
*testimonials
*/

.testimonials {
    .inner-wrapper {
        background-color: $warning-light;
        border-radius: 403px;
        padding: 28px 58px 17px 58px;
        position: relative;

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-top: 35px solid $warning-light;
            border-right: 35px solid transparent;
            border-left: 35px solid transparent;
            position: absolute;
            bottom: -34px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    cite{
        padding-top: 50px;
    }

    p {
        font-weight: 500;
        font-style: italic;
        font-size: rem-calc(25);
    }
}

@media (max-width: 991px) {
    .testimonials {
        .inner-wrapper {
            border-radius: 16px;
            padding: 17px;
            text-align: center;

            &::after {
                border-top: 20px solid $warning-light;
                border-right: 20px solid transparent;
                border-left: 20px solid transparent;
                bottom: -20px;
            }
        }
        cite{
            padding-top: 30px;
        }
        img{
            max-height: 50px;
            max-width: 50px;
        }
        .link1{
            padding: 0px 14px;
        }
        p{
            font-size: rem-calc(18);
        }
    }
}