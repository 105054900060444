#main-navigation {
    display: flex;

    .menu {
        
        &, & ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        & {
            display: flex;
            margin: auto;
        }

        li+li {
            margin-left: rem-calc(20);
        }

        li {
            position: relative;
            margin-bottom: 0;
        }


        a {
            padding: rem-calc(10);
            color: inherit;
            line-height: 1;
            font-size: rem-calc(24);
            font-weight: 700;
            transition: color .2s linear;

            &:hover {
                color: $primary;
            }
        }

        ul {
            display: none;
            position: absolute;
            min-width: 203px;
            background: #fff;
            padding: rem-calc(5);
            border: 5px solid rgba(64, 64, 65, 0.4);
            border-radius: 6px;
            z-index: 1;

            li {
                margin-left: 0;
            }

            a {
                font-weight: 500;
                display: block;
            }
        }
}

    a {
        text-decoration: none;
        display: inline-block;
    }

    .contact {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        li:not(:last-child) {
            margin-bottom: rem-calc(10);
        }


        a {
            font-size: rem-calc(20);
            line-height: 1;
            font-weight: 600;
        }

        svg {
            height: 1.5em;
            width: 1.5em;

            path {
                fill: $secondary;
            }
        }

        .btn {
            font-size: rem-calc(30);
            padding: rem-calc(4) rem-calc(20);
            min-width: 123px;
            font-weight: 400;
            outline: none;
        }
    }
}

.open-menu-btn {
    display: none;

    span {
        display: block;
        height: 3px;
        width: 30px;
        background-color: $body-color;
        border-radius: 3px;
    }

    span:not(:last-child) {
        margin-bottom: rem-calc(5);
    }
}

@include media-breakpoint-up(lg) {
    #main-navigation .menu {

        .dropdown-button {
            display: none;
        }

        li:hover > ul {
            display: block;
        }
    }
}

@include media-breakpoint-down(xxl) {
    #main-navigation {
        z-index: 1000;

        .menu,
        .contact {
            a {
                font-size: rem-calc(20);
            }
        }

        .contact {
            .btn {
                font-size: rem-calc(24);
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    #main-navigation {

        .menu,
        .contact {
            a {
                font-size: rem-calc(16);
            }
        }

        .contact .btn {
            font-size: rem-calc(18)
        }
    }
}

@include media-breakpoint-down(lg) {
    .open-menu-btn {
        display: block;
    }

    #main-navigation {
        position: absolute;
        left: -100%;
        top: 100%;
        width: 100%;
        background: $white;
        min-height: calc(100vh - 100%);
        padding: rem-calc(20) rem-calc(10) rem-calc(20);
        transition: all .2s linear;
        display: block;

        .menu {
            flex-direction: column;
            margin-bottom: rem-calc(40);

            li + li {
                margin: 0;
            }
        }

        li {
            padding-right: 30px;
        }

        .contact  {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
        }

        .menu ul {
            position: static;
            width: 100%;
        }

        .menu .dropdown-button {
            display: block;
            position: absolute;
            right: 10px;
            top: 8px;
            width: 20px;
            height: 20px;
            transform: rotate(315deg);
            transition: all .2s linear;
        }

        .menu .active{

            > .dropdown-button {
                transform: rotate(135deg);
            }
        }
    }

    .menu-open {

        overflow: hidden;

        #main-navigation {
            left: 0;
        }
    }
}
