.logo {
    position: relative;
    text-decoration: none;
    display: inline-block;
    line-height: 1;

    img {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 170px;
    }

    span {
        line-height: 1;
        color: $body-color;
    }

    .lead {
        position: absolute;
        top: 100%;
        right: 0;
        font-size: rem-calc(17);
        font-weight: 600;
    }

    .title {
        font-size: rem-calc(40);
        font-weight: 400;
        font-family: 'League Gothic', sans-serif;
        span {
            color: $secondary;
        }
    }
}

@include media-breakpoint-down(xxl) {
    .logo {
        .title {
            font-size: rem-calc(34);
        }
    }
}

@include media-breakpoint-down(xl) {
    .logo {
        .title {
            font-size: rem-calc(26);
        }

        .lead {
            font-size: rem-calc(12)
        }
    }
}

@include media-breakpoint-down(lg) { 
    .logo {
        .lead, img {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .logo {
        .title {
            font-size: rem-calc(18)
        }
    }
}