/*
* article
*/

article {
    .author {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        img {
            max-width: 70px;
            max-height: 70px;
            margin-right: 15px;
            margin-left: 0;
        }

        p {
            color: $primary;
            font-size: rem-calc(24);
            font-weight: 600;
            text-align: center;
            margin-bottom: 0;

            span {
                color: $black;
                font-size: rem-calc(18);
                font-weight: 400;
                display: block;
            }
        }
    }

    blockquote {
        text-align: justify;

        p {
            &:first-child {
                &::before {
                    content: url("../images/comma-up.png");
                }
            }

            &:last-child {
                &::after {
                    content: url("../images/comma-down.png");
                }
            }
        }
    }

    img {
        margin: 0 20px;
    }

    ol,
    ul {
        margin-bottom: 30px;

        li {
            margin-bottom: 10px;
        }
    }

    ol {
        ul {
            margin-bottom: 0;
        }
    }

    ul {
        ol {
            margin-bottom: 0;
        }
    }

    video,
    iframe {
        width: 100%;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    h1,h2,h3,h4,h5,h6{
        margin-bottom: 1rem;
    }
    h1,h2{
        font-size: rem-calc(45);
    }
    h3{
        font-size: rem-calc(35);
    }
    h4,h5{
        font-size: rem-calc(28);
    }
    h6{
        font-size: rem-calc(24);
    }
}

@media (max-width: 991px) {
    article {
        p {
            margin-bottom: 18px;
        }

        img {
            margin: 0 0 12px 0;
        }
    }
}

@media (max-width: 767px){
    article{
        .author {
            p{
                font-size: rem-calc(20);
                span{
                    font-size: rem-calc(16);
                }
            }
        }
        img{
            display: block;
            float: none !important;
        }
    }
}