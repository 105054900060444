/*
* sidebar
*/
.sidebar{
    background: $blue;
    border-radius: 16px;
    margin: 105px 0px 98px;
    padding: 25px;

    .privacy-seal{
        margin-top: -126px;
        margin-bottom: 42px;
    }

    .contact-list{
        max-width: 321px;
        margin-left: auto;
        margin-right: auto;
    }

    h4{
        font-weight: 700;
        font-family: $font-family-base;
    }

    p{
        margin-bottom: 20px;

        strong{
            font-weight: 600;
            display: block;
        }
    }

    .approval-seal{
        margin-bottom: -120px;
    }

    .privacy-seal,.approval-seal{
        text-align: center;
        img{
            max-height: 188px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
    }
}

/*
* check-list
*/
.check-list{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 28px;

    li{
        position: relative;
        padding-left: 45px;

        &::before{
            content: '';
            background-image: url('../images/check2.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 35px;
            height: 35px;
            border-radius: 100px;
            background-color: $white;
            border: 1.5px solid $black;
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }
}

/*
* contact-list
*/
.contact-list{
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    a {
        text-decoration: none;
        font-weight: 600;
        color: $black;


        &:hover {
            color: $secondary
        }
    }

    li{
        padding-left: rem-calc(50);
        position: relative;
    }

    svg {
        max-width: 40px;
        position: absolute;
        left: 0;
        top: 5px;

        * {
            fill: $black;
        }
    }
}

/*
* gradient-white
*/
.gradient-white{
    position: relative;
    z-index: 1;

    &::before{
        content: '';
        width: 11000px;
        position: absolute;
        top: 100px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient(to top, #FFFFFF 97.4%, rgba(255, 255, 255, 0) 100%);
        z-index: -1;
    }
}

/*
* form
*/
.form{
    h2{
        font-size: rem-calc(35);
        font-weight: 700;
        font-family: $font-family-base;
        margin-bottom: 30px;
    }

    h3 {
        font-size: rem-calc(20);
        font-weight: 700;
        font-family: $font-family-base;
        margin-bottom: rem-calc(17);
    }

    .form-group{
        margin-bottom: 35px;

        
    p{
        font-size: rem-calc(14);
        line-height: calc(21 / 14);
        margin-bottom: 0px;
        margin-top: 10px;
    }
    }

    .form-control{
        height: 55px;
        border-radius: 5px;
        border-color: rgba($gray-100, .75);
        color: $gray-100;
        font-family: $font-family-base;
        font-weight: 500;
        font-size: rem-calc(20);

        &::placeholder{
            color: $gray-100;
        }
    }

    .selectbox{
        position: relative;

        .form-control{
            padding-right: 40px;
        }

        &::after{
            content: '';
            background-image: url('../images/caret-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            width: 23px;
            height: 13px;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    .form-block{
        background: rgba($blue, .15);
        border-radius: 5px;
        padding: 40px 24px 5px;
    }

    .form-heading{
        font-weight: 600;
        margin-bottom: 15px;
    }

    .column {
        margin-bottom: rem-calc(30);
    }

}

@media(max-width: 991px){
    .form{
        h2{
            font-size: rem-calc(24);
        }
    
        .form-control{
            height: 48px;
            font-size: rem-calc(16);
        }
    
        .selectbox{    
            &::after{
                width: 20px;
            }
        }
    }
}

@media(max-width: 767px){
    .form{
        h2{
            font-size: rem-calc(18);
            margin-bottom: 15px;
        }
    
        .form-control{
            height: 40px;
        }
    
        .form-block{
            padding: 20px 20px 1px;
        }

        .btn{
            font-size: rem-calc(14);
            padding: 15px 25px;
        }
    }
}

.font-poppins{
    font-family: $font-family-base!important;
}

.fw-semibold{
    font-weight: 600;
}

@media (min-width: 992px){
    .w-lg-100{
        width: 100%;
    }
}