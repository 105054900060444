.box {
    padding: rem-calc(115) rem-calc(30) rem-calc(30);
    position: relative;

    .inner {
        max-width: 408px;
        margin: auto;
        text-align: center;
        position: relative;
        padding-bottom: rem-calc(40);

        &::after {
            content: "";
            position: absolute;
            width: 100px;
            height: 4px;
            background-color: $body-color;
            border-radius: 20px;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }


    &.text-white {
        .inner::after {
            background-color: $white;
        }
    }



    .tag {
        position: absolute;
        right: 0;
        top: 30px;
        display: inline-block;
        padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(40);
        font-size: rem-calc(17);
        line-height: 1;
        // box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
        font-weight: 600;
        color: $body-color;
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 6% 50%);
        filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 100%;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 6% 50%);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), #F9D951;
            z-index: -1;
        }
    }


   > P {
        font-weight: 600;
    }


    .list2 {
        max-width: 738px;
        margin: auto;
        margin-bottom: rem-calc(30);
    }

    .figure1 {
        position: absolute;
        top: -38px;

        &.rotate-45 {
            left: -50px;
        }
    }

}

.box .button-wrapper, .section .button-wrapper {
    max-width: 418px;
    margin: 0 auto;
}

.box .title,
.title-container .title {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-size: rem-calc(60);
    margin-bottom: rem-calc(16);
}

.box .subtitle,
.title-container .subtitle {
    font-family: $font-family-base;
    font-weight: 700;
    font-size: rem-calc(20);
}

.title-container {
    padding: 0 rem-calc(15);
    text-align: center;
    margin-bottom: rem-calc(150);
}


.box1 {
    position: relative;
    text-align: center;
    background-color: $white;
    border-radius: 19px;
    padding: rem-calc(90px) rem-calc(30) rem-calc(30);
    max-width: 532px;
    margin-left: auto;

    img {
        max-width: 185px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

    }

    p:not(:nth-last-of-type(1)) {
        margin-bottom: 0;
    }

    .title {
        font-size: rem-calc(24);
        font-weight: 700;
    }

    .btn {
        font-size: rem-calc(35);
        min-width: 280px;
        outline: 5px solid rgba(0, 0, 0, 0.4);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}


@include media-breakpoint-down(xxl) {


    .box .title,
    .title-container .title {
        font-size: rem-calc(50);

        .box1 {
            p {
                font-size: rem-calc(18);
            }

            strong {
                font-size: rem-calc(20);
            }

            .btn {
                font-size: rem-calc(25);
            }
        }
    }
}

@include media-breakpoint-down(lg) {

    .box1 {
        margin: 0 auto rem-calc(20);
    }

    .box {
        & .button {
            font-size: rem-calc(30);

        }
    }

    .box .title,
    .title-container .title {
        font-size: rem-calc(42);
    }
}

@include media-breakpoint-down(md) {
    .box {
        .tag {
            top: 50px;
        }
    }

    .box1 .title {
        font-size: rem-calc(14);
    }

    .title-container {
        padding-top: rem-calc(35);
    }
}

@include media-breakpoint-down(sm) {

    .box .title,
    .title-container .title {
        font-size: rem-calc(36px);
    }

    .box {
       > p {
            font-size: rem-calc(12);
        }

        .inner {
            padding-bottom: 0;

            &::after {
                content: none;
            }
        }

        .figure1 {
            img {
                max-height: 100px;
            }

            top: 82px;
            left: -18px;


            &.figure1.rotate-45 {
                left: -31px;
                top: 95px;
            }
        }
    }

    .box .subtitle,
    .title-container .subtitle {
        font-size: rem-calc(14);
    }


    .box1 .btn {
        min-width: 220px;
    }

    .title-container {
        margin-bottom: rem-calc(100);
    }
}

@include media-breakpoint-between(sm, lg) {
    .box .figure1 img {
        height: 140px;
    }
}

@include media-breakpoint-between(sm, md) {
    .box .figure1 {
        top: 47px;
    }

    .box .figure1.rotate-45 {
        left: 10px;
    }
}
