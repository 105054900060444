/*
*h-css
*/

//questions
.questions {
    padding: 50px 80px;

    h6 {
        font-weight: 700;
        font-family: $font-family-base;
    }

    p {
        font-weight: 500;
        font-size: rem-calc(20);
    }
}

@include media-breakpoint-down(md) {
    .questions {
        padding: 34px 0 0 0;

        h6,
        p {
            font-size: rem-calc(18);
        }
    }
}


//display1
.display1 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $primary;
}

@include media-breakpoint-down(md) {
    .display1 {
        &.alt {
            color: $black;
        }
    }
}

.display2 {
    font-family: "lobster", sans-serif;
    font-weight: 700;
    color: $primary;
}

//icon
.icon {
    svg {
        margin-bottom: 21px;
    }

    &.alt {
        svg {
            max-width: 45px;
            max-height: 45px;
        }
    }

    &.positioned {
        img {
            margin-top: -165px;
            position: relative;
        }
    }
}

@include media-breakpoint-down(md) {
    .icon.alt {
        svg {
            path {
                fill: $black;
            }
        }
    }
}

//subtitle 
.subtitle {
    display: block;
    padding-top: 15px;
    font-size: rem-calc(24);
    font-family: $font-family-base;
    font-weight: 700;
}

@include media-breakpoint-down(md) {
    .subtitle {
        font-size: rem-calc(18px);
    }
}

//caption
.caption {
    font-size: rem-calc(24);
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .caption {
        font-size: rem-calc(18);

        img {
            margin-left: 22px;
            max-height: 28px;
            max-width: 88px;
        }
    }
}

//top-border
@media (min-width: 991px) {
    .top-border {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            background-color: $blue;
            height: 2px;
            width: 110%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

//link1
.link1 {
    color: $black;
    font-size: rem-calc(18);
    font-weight: 400;
}

//rotate-45
.rotate-45 {
    transform: rotate(-7deg);
}

.font1 {
    font-family: "lobster", sans-serif;
}

.icon-wrapper {
    margin-top: -110px;

    img {
        vertical-align: top;
    }
}

@media(max-width: 991px) {
    .icon-wrapper {
        img {
            max-width: 200px;
        }
    }
}

@media(max-width: 767px) {
    .icon-wrapper {
        .positioned {
            position: absolute;
            bottom: -85px;
            left: 50%;
            transform: translate(-50%);
        }
    }
}

@media(max-width: 767px) {
    .gradient1 {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -32px;
            width: 100%;
            height: 32px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EAF4FC 100%);
            transform: rotate(180deg);
        }
    }
}

.breadcrumb{
    background: aliceblue;
    padding: 15px 50px;
    li{
        font-size: rem-calc(22);
    }
}

@media (max-width: 767px){
    .breadcrumb{
        padding: 15px 20px;
        li{
            font-size: rem-calc(15);
        }
    }
}