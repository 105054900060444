/*
*accordion
*/

.accordion {
    margin-right: 50px;
    .accordion-button {
        box-shadow: none;
        font-size: rem-calc(30);
        font-family: "lobster", sans-serif;
        &::after {
            background-image: url("../images/minus.svg");
        }
        &.collapsed{
            &::after{
                background-image: url("../images/plus.svg");
            }
        }
    }
    .active{
        color: $primary;
    }
    .accordion-body{
        padding: 0;
        h6{
            font-family: $font-family-base;
        }
    }
}