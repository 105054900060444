.two-column-layout {
    display: flex;
    border-radius: 16px;

    > .box {
        flex: 0 0 50%;

        .list2 {
            max-width: 480px;
        }
    }
}

@include media-breakpoint-down(md) {
    .two-column-layout {
         flex-direction: column;
         border-radius: 0;
    }

    .two-column-layout {
        clip-path: polygon(0 0, 100% 0, 100% 97%, 50% 100%, 0 97%);
    }
}